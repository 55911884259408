<template>
  <relatorio :headers="headersRel" />
</template>
<script>
  import Relatorio from './Relatorio.vue'
  import { sortCurrency } from '../../utils/sorts'

  export default {
    components: {
      Relatorio,
    },
    data () {
      return {
      }
    },
    computed: {
      headersRel () {
        return [
          { align: 'left', sortable: true, text: 'Status', value: 'status', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, text: 'Técnico', value: 'tecnico', width: 'auto', floatingfilter: true, report: true },
          { align: 'right', sortable: true, text: 'Nº Produtores', value: 'produtores', width: 'auto', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Utilizam App', value: 'produtores_app', width: '10px', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Perc.', value: 'percentual_app', width: '10px', sort: sortCurrency, floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Cot. Produtor', value: 'cotacao_produtor', width: '10px', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Cot. Técnico', value: 'cotacao_tecnico', width: '10px', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Ped. Produtor', value: 'pedido_produtor', width: '10px', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Ped. Técnico', value: 'pedido_tecnico', width: '10px', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Ped. Supervisor', value: 'pedido_supervisor', width: '10px', floatingfilter: false, report: true },
          { align: 'right', sortable: true, text: 'Ped. Faturados', value: 'pedidos_faturados', width: '10px', report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Vlr. Faturado', value: 'valor_total_faturado', width: '10px', sort: sortCurrency, floatingfilter: false, report: true, sum: 'Money' },
          { align: 'right', sortable: true, text: 'Média/Pedido', value: 'media_pedido', width: '10px', sort: sortCurrency, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Média/Produtor', value: 'media_produtor', width: '10px', sort: sortCurrency, report: true, styleReport: { halign: 'right' } },
          { align: 'left', sortable: true, text: 'Unidades', value: 'unidades', width: 'auto', floatingfilter: true, report: true },
        ]
      },
    },
  }
</script>
